import Layout from "../components/layout"
import React from "react"
import PostCard from "../components/card"
import ScrollAnimation from "react-animate-on-scroll"
import {
  InsightsCol,
  InsightsRow,
  InsightsContainer,
  Title,
  InsightsContent,
  InsightsWrapper,
  Link,
} from "../styles/insights/style"

const Insights = ({ data }) => {
  const posts = data.allWordpressPost.edges.map(({ node }) => {
    const {
      slug,
      featured_media: { source_url },
      title,
      excerpt,
      categories,
    } = node
    return (
      <InsightsCol md={6} lg={4} key={slug}>
        <Link to={'/'+node.slug} key={slug}>
          <ScrollAnimation animateIn={"fadeIn"} duration={2}>
            <PostCard
              id={slug}
              src={source_url}
              title={title}
              description={excerpt}
              category={categories.map(c => c.name).join("")}
              color={"white"}
            />
          </ScrollAnimation>
        </Link>
      </InsightsCol>
    )
  })

  const latestPressClippingData = {
    slug: "/press-clipping",
    source_url: "/BOOK MOCK UP.jpg",
    title: "350 banks, 350.000 branches.",
    category: "BOOK REVIEW BY DOUGLAS BLAKEY",
    excerpt:
      "Allen founded Allen International in 1992. He grew the firm to be the world leader in financial services for branding and retail design. In nearly 30 years, he worked with around 350 banks, with his design projects responsible for over 350,000 branches.",
  }

  const latestPostData = {
    slug: "/latest-publication",
    source_url: "/BOOK MOCK UP.jpg",
    title: "Latest Publication",
    category: "A TOTAL BUNCH OF BANKERS",
    excerpt:
      "A retrospective by Michael Allen.  A guide to retail bank design and creativity for financial service companies, improving the customer experience and their brand image [...]",
  }

  const latestPost = (
    <InsightsCol md={6} lg={4} key={latestPostData.slug}>
      <Link to={latestPostData.slug} key={latestPostData.slug}>
        <ScrollAnimation animateIn={"fadeIn"} duration={2}>
          <PostCard
            id={latestPostData.slug}
            src={latestPostData.source_url}
            title={latestPostData.title}
            description={latestPostData.excerpt}
            category={latestPostData.category}
            color={"white"}
          />
        </ScrollAnimation>
      </Link>
    </InsightsCol>
  )

  const latestPressClipping = (
    <InsightsCol md={6} lg={4} key={latestPressClippingData.slug}>
      <Link to={latestPressClippingData.slug} key={latestPressClippingData.slug}>
        <ScrollAnimation animateIn={"fadeIn"} duration={2}>
          <PostCard
            id={latestPressClippingData.slug}
            src={latestPressClippingData.source_url}
            title={latestPressClippingData.title}
            description={latestPressClippingData.excerpt}
            category={latestPressClippingData.category}
            color={"white"}
          />
        </ScrollAnimation>
      </Link>
    </InsightsCol>
  )

  posts.push(latestPost)
  posts.push(latestPressClipping)

  return (
    <Layout>
      <InsightsWrapper>
        <InsightsContent>
          <Title>Insights</Title>
          <InsightsContainer fluid>
            <InsightsRow>{posts}</InsightsRow>
          </InsightsContainer>
        </InsightsContent>
      </InsightsWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          content
          slug
          title
          id
          date
          excerpt
          categories {
            name
          }
          featured_media {
            source_url
          }
        }
      }
    }
  }
`

export default Insights
