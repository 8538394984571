import "animate.css/animate.min.css"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"

const Link = styled(GatsbyLink)`
  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
`

const InsightsWrapper = styled.div`
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  padding-top: ${({
    theme: {
      header: { mobile },
    },
  }) => mobile};
  min-height: calc(
    100vh -
      ${({
        theme: {
          footer: { mobile },
        },
      }) => mobile}
  );
  color: white;

  @media only screen and (min-width: 576px) {
    padding-top: ${({
      theme: {
        header: { desktop },
      },
    }) => desktop};
    min-height: calc(
      100vh -
        ${({
          theme: {
            footer: { desktop },
          },
        }) => desktop}
    );
  }
`

const InsightsContent = styled.div`
  padding-top: 6rem;
  padding-bottom: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 768px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 1900px) {
    padding: ${({
      theme: {
        content: {
          padding: { desktop },
        },
      },
    }) => desktop};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }
`

const Title = styled.h1`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s108};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  padding-left: 30px;
`

const InsightsContainer = styled(Container)`
  padding: 0 40px;
`

const InsightsRow = styled(Row)`
  margin: 0 -40px;
`
const InsightsCol = styled(Col)`
  padding: 0 40px;
`

export {
  InsightsCol,
  InsightsRow,
  InsightsContainer,
  Title,
  InsightsContent,
  InsightsWrapper,
  Link,
}
